<template>
    <div class="mt-2 p-0 mr-0 ml-0 mb-0">
        <filter-component @callLoadItems="loadItems"></filter-component>
      
        <v-btn class="mt-4" @click.prevent="newItem()" small :class="$css_button" color="secondary" depressed>novo contrato</v-btn>    

        <v-btn class="mt-4 ml-3" @click.prevent="rentalExport()" small :class="$css_button" color="excel" dark depressed>exportar excel</v-btn>    
      

        <div style="margin-bottom: 50px;">
            <report-component v-if="listitems" :object="listitems" :filters="params" @callLoadItems="loadItems" @callEditItem="editItem" ref="report"></report-component>
        </div>

        <!-- Dialog -->
        <v-dialog 
            v-if="item && item != ''"
            v-model="dialog" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" color="white">
                <v-card-title class="text-h5">
                    Contrato
                    <span v-if="item.codigo" class="ml-2">- Código {{item.codigo}} </span>
                    <span v-if="item.codigo" class="ml-6 text-subtitle-1"> 
                        <div>
                            <span class="font-weight-medium">Endereço:</span>     
                            {{item.endereco}} 
                            <span v-if="item.numero">, {{ item.numero }}</span>
                            <span v-if="item.apartamento"> APTO {{ item.apartamento }}</span>
                            <span v-if="item.complemento"> {{ item.complemento }}</span>
                        </div>
                    </span>

                    <span v-if="item.codigo" class="ml-6 text-subtitle-1">                         
                        <div>
                            <span class="font-weight-medium">Proprietários:</span> 
                            <span v-if="item.json_rental_owners && item.json_rental_owners.length > 0">
                                <span v-for="(cl, index) in item.json_rental_owners" :key="cl.id">
                                    {{ cl.nome }}
                                    <span v-if="(index + 1) < item.json_rental_owners.length">, </span>
                                </span>
                            </span>
                        </div>
                    </span>                        

                    <span v-if="item.codigo" class="ml-6 text-subtitle-1">     
                        <div>
                            <span class="font-weight-medium">Locatários:</span> 
                            <span v-if="item.json_rental_tenants && item.json_rental_tenants.length > 0">
                                <span v-for="(cl, index) in item.json_rental_tenants" :key="cl.id">
                                    {{ cl.nome }}
                                    <span v-if="(index + 1) < item.json_rental_tenants.length">, </span>
                                </span>
                            </span>
                        </div>

                    </span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="openCloseDialog()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <insert-edit-component :object="item" @callLoadItem="loadItem" :edit_active_tab="edit_active_tab"></insert-edit-component>   
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->


        <!-- Icon new -->
        <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn fab dark color="secondary" fixed right bottom @click.prevent="newItem()">
                    <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                </v-btn>
            </template>
            <span>New</span>
        </v-tooltip>
        <!-- End Icon new -->

    </div>
</template>

<script>
import BreadCrumbComponent from '../../../layouts/BreadCrumbComponent.vue'
import ReportComponent from './ReportComponent.vue'
import InsertEditComponent from './InsertEditComponent.vue'
import FilterComponent from './FilterComponent.vue'

export default {
    components:{
        BreadCrumbComponent,
        ReportComponent,
        InsertEditComponent,
        FilterComponent
    },
    
    created() {
        this.loadItems()
    },

    computed: {
        listitems2() {
            return this.$store.state.rentals.items    
        },

        me () {
          return this.$store.state.auth.me
        },
    },    

    data () {
        return {
            item: {},
            listitems: {},
            edit_active_tab: '',
            dialog: false,

            breadcrumbs: [
                {
                    text: 'Inicial',
                    disabled: false,
                    href: 'dashboard',
                },
                {
                    text: 'Usuários',
                    disabled: true,
                    href: 'users',
                },
            ],

            panel_search: [],

            params: {
                id: '',
                multiple_status:['Em vigência'] 
            },

            excel: {
                fields: {
                   // 'Days'                      : 'days',
                },

                data: [],   
            },

        }
    },


    methods: {
       
        loadItems (params = '') {
            this.$store.commit('STORE_USER', false)
            this.edit_active_tab = ''

            if(this.params.codes && this.params.codes != ''){
                this.params.page = 1
            }
            if(this.$route.params.rental_id && this.$route.params.rental_id != ''){
                this.params.id = this.$route.params.rental_id
                this.$route.params.rental_id = ''
            }
            if(this.$route.params.id && this.$route.params.id != ''){
                this.params.id = this.$route.params.id
                this.$route.params.id = ''
            }
            if(this.$route.params.days && this.$route.params.days > 0){
                this.params.days = this.$route.params.days
                this.$route.params.days = ''
            }
            if(this.$route.params.renewal_open && this.$route.params.renewal_open != ""){
                this.params.renewal_open = this.$route.params.renewal_open
                this.$route.params.renewal_open = ''
            }

            // in case is exists params from search is require set null all $route params
            if(params != ''){
                this.params = {} 
                this.params = params
                this.$route.params.renewal_open = ''
                this.$route.params.days = ''
            }
            
            this.$store.dispatch('loadPromisseRentals', this.params) 
                        .then(response => {
                            this.listitems = response

                            if(this.$route.params.rental_monthly_fee_id && this.$route.params.rental_monthly_fee_id != ""){
                                this.editItem(this.params.id)
                                this.$route.params.rental_monthly_fee_id = ''
                                this.edit_active_tab = 'tab-5'
                            }
                        })
        },


        newItem(){
            this.item = {}
            this.dialog = true
        },

        loadItem(id){
            this.$store.dispatch('loadRental', id) 
                        .then(response => {
                            this.item = response.data
                        })
        },

        editItem (id) {    
            this.$store.dispatch('loadRental', id) 
                        .then(response => {
                            this.item = response.data
                            this.dialog = true
                        })
                                   
        },
        
        openCloseDialog(){
            if(this.item.id != undefined && this.store_user == true){
                this.params = {}
                this.params.id = this.item.id
            }else{
                this.$store.commit('STORE_USER', false)
            }
            this.item = {}
            this.dialog = !this.dialog
            if(this.dialog == false){
                this.loadItems(this.params)
            }
        },

        rentalExport(){
            this.$store.dispatch('rentalRentalMonthlyFeeExport', this.params)
        }
    }

}
</script>

<style scoped>
.subtitle {
  min-height: 150px;
}
</style>