<template>
    <div>
        <v-row dense>
           <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                <v-card
                    class="mx-auto pa-3"
                    outlined
                    min-height="60px"
                    @click="rentalMonthlyFeeAlertReport({ payment_date_null: true })"  
                    :color="alert_colors.payment_date_alert" 
                >
                    <v-row>
                        <v-col cols="3">
                           <v-icon x-large color="red">mdi-account-cash-outline</v-icon>
                        </v-col>
                        <v-col class="text-left" cols="9"> 
                            <div>
                                <div class="text-subtitle-2" style="color: black;">Aluguel vencido</div>
                                <div class="text-body-2" style="color: black;">{{ alerts.payment_date_alert }}</div>
                            </div>
                        </v-col>
                    </v-row>
                    
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                <v-card
                    class="mx-auto pa-3"
                    outlined
                    min-height="60px"
                    @click="rentalMonthlyFeeAlertReport({ transfer_owner_date_null: true })" 
                    :color="alert_colors.transfer_owner_date_alert"
                >
                    <v-row>
                        <v-col cols="3">
                           <v-icon x-large color="orange">mdi-account-cash-outline</v-icon>
                        </v-col>
                        <v-col class="text-left" cols="9"> 
                            <div>
                                <div class="text-subtitle-2" style="color: black;">Repasse pendente</div>
                                <div class="text-body-2" style="color: black;">{{ alerts.transfer_owner_date_alert }}</div>
                            </div>
                        </v-col>
                    </v-row>
                    
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                <v-card
                    class="mx-auto pa-3"
                    outlined
                    min-height="60px"
                    @click="rentalMonthlyFeeAlertReport({ update_value_yes: true })" 
                    :color="alert_colors.update_value_alert"
                >
                    <v-row>
                        <v-col cols="3">
                           <v-icon x-large color="green">mdi-account-cash-outline</v-icon>
                        </v-col>
                        <v-col class="text-left" cols="9"> 
                            <div>
                                <div class="text-subtitle-2" style="color: black;">Atualiza aluguel</div>
                                <div class="text-body-2" style="color: black;">{{ alerts.update_value_alert }}</div>
                            </div>
                        </v-col>
                    </v-row>
                    
                </v-card>
            </v-col>
        </v-row>    

        <v-row v-if="items && items.length > 0">
            <v-col cols="12">
                <v-btn class="mb-3" @click.prevent="rentalRentalMonthlyFeeAlertReportExport(params)" small :class="$css_button" color="excel" dark depressed>exportar excel</v-btn>  

                <v-card
                    class="mx-auto pa-3"
                    outlined
                    min-height="60px"
                >
                    <v-data-table
                        :headers="headers"
                        :items="items"
                    >
                        <template v-slot:[`item.codigo`]="{ item }"> 
                            <span :class="$css_report_list">{{ item.codigo }}</span>
                        </template>

                        <template v-slot:[`item.endereco`]="{ item }"> 
                            <div class="pa-2">
                                <span :class="$css_report_list + ' font-weight-medium'">
                                    {{ item.endereco }}
                                    <span v-if="item.numero">, {{ item.numero }}</span>
                                    <span v-if="item.apartamento"> APTO:  {{ item.apartamento }}</span>
                                    <span v-if="item.complemento"> Compl:  {{ item.complemento }}</span>
                                </span>
                                <div :class="$css_report_list + ' mt-2 '">
                                    <div>
                                        <span class="font-weight-medium">Proprietários:</span> 
                                        <span v-if="item.json_rental_owners && item.json_rental_owners.length > 0">
                                            <span v-for="(cl, index) in item.json_rental_owners" :key="cl.id">
                                                {{ cl.nome }}
                                                <span v-if="cl.telefone || cl.email">
                                                    (
                                                    <span class="mr-2" v-if="cl.telefone">
                                                        Tel: {{ cl.telefone }}
                                                    </span>
                                                    <span class="mr-2" v-if="cl.email">
                                                        e-mail: {{ cl.email }}
                                                    </span>
                                                    )
                                                </span>
                                                <span v-if="(index + 1) < item.json_rental_owners.length">, </span>
                                            </span>
                                        </span>
                                    </div>

                                    <div>
                                        <span class="font-weight-medium">Locatários:</span> 
                                        <span v-if="item.json_rental_tenants && item.json_rental_tenants.length > 0">
                                            <span v-for="(cl, index) in item.json_rental_tenants" :key="cl.id">
                                                {{ cl.nome }}
                                                <span v-if="cl.telefone || cl.email">
                                                    (
                                                    <span class="mr-1" v-if="cl.telefone">
                                                        Tel: {{ cl.telefone }}
                                                    </span>
                                                    <span class="mr-1" v-if="cl.email">
                                                        e-mail: {{ cl.email }}
                                                    </span>
                                                    )
                                                </span>
                                                <span v-if="(index + 1) < item.json_rental_tenants.length">, </span>
                                            </span>
                                        </span>

                                    </div>
                                    </div>
                            </div>     
                        </template>

                        <template v-slot:[`item.total`]="{ item }"> 
                            <v-btn class="mt-4" @click.prevent="showMonthlyFees(item)" small :class="$css_button" color="secondary" depressed>Mensalidades</v-btn>
                        </template> 

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>    

        <!-- Dialog -->
        <v-dialog 
            v-if="rental_item && rental_item != ''"
            v-model="dialog.showMonthlyFees" 
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-system-bar
                dark
                color="primary"
                height="10px"
            >    
            </v-system-bar>    

            <v-card class="d-print-none" color="white">
                <v-card-title class="text-h5">
                    Contrato
                    <span v-if="rental_item.codigo" class="ml-2">- Código {{rental_item.codigo}} </span>
                    <span v-if="rental_item.codigo" class="ml-6 text-subtitle-1"> 
                        <div>
                            <span class="font-weight-medium">Endereço:</span>     
                            {{rental_item.endereco}} 
                            <span v-if="rental_item.numero">, {{ rental_item.numero }}</span>
                            <span v-if="rental_item.apartamento"> APTO {{ rental_item.apartamento }}</span>
                            <span v-if="rental_item.complemento"> {{ rental_item.complemento }}</span>
                        </div>
                    </span>

                    <span v-if="rental_item.codigo" class="ml-6 text-subtitle-1">                         
                        <div>
                            <span class="font-weight-medium">Proprietários:</span> 
                            <span v-if="rental_item.json_rental_owners && rental_item.json_rental_owners.length > 0">
                                <span v-for="(cl, index) in rental_item.json_rental_owners" :key="cl.id">
                                    {{ cl.nome }}
                                    <span v-if="(index + 1) < rental_item.json_rental_owners.length">, </span>
                                </span>
                            </span>
                        </div>
                    </span>                        

                    <span v-if="rental_item.codigo" class="ml-6 text-subtitle-1">     
                        <div>
                            <span class="font-weight-medium">Locatários:</span> 
                            <span v-if="rental_item.json_rental_tenants && rental_item.json_rental_tenants.length > 0">
                                <span v-for="(cl, index) in rental_item.json_rental_tenants" :key="cl.id">
                                    {{ cl.nome }}
                                    <span v-if="(index + 1) < rental_item.json_rental_tenants.length">, </span>
                                </span>
                            </span>
                        </div>

                    </span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="showDialog('showMonthlyFees')">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-data-table
                                :headers="monthly_headers"
                                :items="rental_item.rental_monthly_fees_json"
                            >
                                <template v-slot:[`item.due_date`]="{ item }">
                                    <v-chip
                                        :color="statusDueDate(item)"
                                        label
                                    >
                                        {{ formatDate(item.due_date) }}
                                    </v-chip>               
                                </template>

                                <template v-slot:[`item.payment_date`]="{ item }">
                                    <v-chip
                                        v-if="item.payment_date"
                                        label
                                    >
                                        {{ formatDate(item.payment_date) }}
                                    </v-chip>
                                </template>

                                <template v-slot:[`item.transfer_owner_date`]="{ item }">
                                    <v-chip
                                        v-if="item.transfer_owner_date"
                                        label
                                    >
                                        {{ formatDate(item.transfer_owner_date) }}
                                    </v-chip>
                                </template>

                                <template v-slot:[`item.total`]="{ item }">
                                    {{ totalTenant(item.json_rental_monthly_fee_values) | toCurrency }}
                                </template>

                                <template v-slot:[`item.adm`]="{ item }">
                                        {{ totalAdm(item.json_rental_monthly_fee_values) | toCurrency }}
                                </template>

                                <template v-slot:[`item.repasse`]="{ item }">
                                        {{ totalOwner(item.json_rental_monthly_fee_values) | toCurrency }}
                                </template>

                                <template v-slot:[`item.actions`]="{ item }">
                                    
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <router-link :to="{ 
                                                name: 'admin.rentals.index', 
                                                params: {
                                                    rental_id: rental_item.id,
                                                    rental_monthly_fee_id: item.id                          
                                                } 
                                            }">
                                                <v-btn icon class="ml-8">
                                                    <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                                                </v-btn>
                                            </router-link>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                    
                                    
                                    <v-tooltip top>
                                        <template v-if="item.update_value && item.update_value == 'Sim'" v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                class="ml-6"
                                                v-bind="attrs"
                                                v-on="on"
                                                color="red"
                                            >
                                                mdi-update
                                            </v-icon>
                                        </template>
                                        <span>Alerta de atualização de aluguel</span>
                                    </v-tooltip> 
                                </template>   

                            </v-data-table>
                        </v-col>
                    </v-row>     
                </v-container>
            </v-card>   
        </v-dialog>
        <!-- End Dialog -->
        
    </div>
</template>

<script>
export default {
    created() {
        this.rentalMonthlyFeeAlerts()
    },

    data () {
        return {
            rental_item: {},
            params: {},
            alert_selected_color: '#F3E5F5',

            dialog: {
                showMonthlyFees: false
            },

            alert_colors: {
                payment_date_alert: 'white',
                transfer_owner_date_alert: 'white',
                update_value_alert: 'white'
            },

            alerts: {
                payment_date_alert: 0,
                transfer_owner_date_alert: 0,
                update_value_alert: 0
            },

            headers: [
                { text: 'CODIGO', value: 'codigo' },
                { text: 'DETALHES', value: 'endereco' },
                { text: 'MENSALIDADES', value: 'total' },
            ],
            
            monthly_headers: [
                { text: 'DT VENC.', value: 'due_date' , sortable: false},
                { text: 'DT PGTO.', value: 'payment_date' , sortable: false},
                { text: 'DT REPASE', value: 'transfer_owner_date' , sortable: false},
                { text: 'TOTAL R$', value: 'total' , sortable: false},
                { text: 'ADM + CREDITOS R$', value: 'adm' , sortable: false},
                { text: 'REPASSE R$', value: 'repasse' , sortable: false},
                { text: 'AÇÕES', value: 'actions', sortable: false }
            ],

            items: []
        }
    },

    methods: {
        rentalMonthlyFeeAlerts(){
            this.$store.dispatch('rentalMonthlyFeeAlerts') 
                        .then(response => {
                            this.alerts = response.data
                        })
        },

        rentalMonthlyFeeAlertReport(params){
            this.params = params
            this.alert_colors.payment_date_alert = 'white'
            this.alert_colors.transfer_owner_date_alert = 'white'
            this.alert_colors.update_value_alert = 'white'

            if(this.params.payment_date_null){
                this.alert_colors.payment_date_alert = this.alert_selected_color
            }
            if(this.params.transfer_owner_date_null){
                this.alert_colors.transfer_owner_date_alert = this.alert_selected_color
            }
            if(this.params.update_value_yes){
                this.alert_colors.update_value_alert = this.alert_selected_color
            }

            this.$store.dispatch('rentalMonthlyFeeAlertReport', this.params) 
                        .then(response => {
                            this.items = response.data
                        })
        },

        rentalRentalMonthlyFeeAlertReportExport(params){
            this.$store.dispatch('rentalRentalMonthlyFeeAlertReportExport', params)
        },

        showMonthlyFees(item){
            this.rental_item = item
            this.showDialog('showMonthlyFees')
        },

        showDialog(dialog_name){
            if(dialog_name == 'showMonthlyFees'){
                this.dialog.showMonthlyFees = !this.dialog.showMonthlyFees
            }
        }
    },
    
}
</script>